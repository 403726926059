.lightsensitivity-test, .lightsensitivity-test-instructions {
    
    h1, h2 {
        color: #FFF;
        font-family: $font-filson-pro;
    }

    h2 {
        margin-bottom: 1em;
        font-size: 40px;
    }

    .flex-col {
        align-items: center;
        height:100%;
    }

    .flex-row {
        align-self: flex-start;
        align-items: center;

        img {
            z-index: 1;
        }
    }

    &__test__progressbar-container {
        @extend .flex-center;
        margin: -0.5em 0 1em;
    }

    .swipeInstruction {
        font-size: 24px;
        color: #FFF;
    }

    .insContainer {
        font-size: 30px;
        text-align: center;
        width: 90%;
        height: 70px;
        padding-top: 15px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 0px $button-red;
        border-radius: 15px;
        margin-bottom: 30px;
    }

    &__test__image-container {
        flex: 1;
        width: 90%;
        height: 692px;
        //@extend .flex-center;
        // background: #FFFFFF 0% 0% no-repeat padding-box;
        // box-shadow: 0px 4px 0px $button-red;
        // border-radius: 30px;

        &--animation-expand {
            animation: imageContainerExpand 500ms forwards;
        }
    }

    &__answer__grid {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 90%;
        gap: 2em;
        padding: 0.25em;
    }

    &__grid-item {
        @extend .flex-center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 0px $button-red;
        border-radius: 15px;
        opacity: 1;
    }

    .direction__left {
        -webkit-transform:rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .direction__up {
        -webkit-transform:rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .direction__right {
        -webkit-transform:rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    .opacity100 {
        opacity: 1;
    }

    .opacity70 {
        opacity: 0.7;
    }

    .opacity40 {
        opacity: 0.4;
    }

    .opacity20 {
        opacity: 0.2;
    }

    .opacity10 {
        opacity: 0.1;
    }

    .stage1 {
        width: 120px;
        height: 120px;
    }

    .stage2 {
        width: 100px;
        height: 100px;
    }

    @keyframes imageContainerExpand {
        from {
            position: relative;
        }
        to {
            width: 100%;
            height: 100%;
            position: fixed;
            border-radius: 0px;
        }

    }

    @media (max-width: $screen-lg), (min-width: $screen-lg) {
        padding: 3em 2em; 
        
        .flex-row {
            h1 {
                font-size: 2.5em;
                margin-left: 1em;
            }
        }

        &__test__progressbar-container {
            width: 90%;
    
            img {
                width: 80%;
            }
        }

        &__grid-item {
            font-size: 70px;
        }
    }
    
    @media (max-width: $screen-md) {
        .flex-row {
            h1 {
                font-size: 2em;
                margin-left: 1em;
            }
        }

        .insContainer {
            font-size: 21px;
            margin-bottom: 30px;
        }

        &__grid-item {
            font-size: 56px;
        }

    }

    @media (max-width: $screen-sm) {
        padding: 1.5em 1em; 

        h2 {
            font-size: 1em;
            margin-bottom: 0.5em;
        }

        .flex-row {
            h1 {
                font-size: 1.25em;
                margin-left: 0.5em;
            }
        }

        &__test__progressbar-container {
            width: 100%;
    
            img {
                width: 100%;
            }
        }

        .swipeInstrcution {
            font-size: 12px;
        }

        .insContainer {
            font-size: 21px;
        }

        &__answer__grid {
            gap: 1em; 
            img {
                width: 50%;
            }
        }

        &__grid-item {
            font-size: 48px;
        }
    }
    
    @media (max-width: $screen-xs) {
        .insContainer {
            font-size: 16px;
            padding-top: 10px;
            height: 45px;
            margin-bottom: 15px;
        }

        &__grid-item {
            font-size: 32px;
        }
    }
}