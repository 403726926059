.my-badges {
    h1 {
        color: $theme;
        margin-left: 0.5em;
    }

    .flex-col {
        height: 100%;
        justify-content: space-evenly;
    }

    .flex-row {
        align-items: center;
    }


    &__badges__grid {
        align-self: center;
        display: grid;
        grid-template-columns: 160px 160px 160px;
        gap: 1.5em;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__badge--muted {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: $screen-lg), (min-width: $screen-lg) {
    .my-badges {
        padding: 2rem 3rem;

        .flex-col {
            justify-content: space-around;
        }

        &__badges__grid {
            align-self: center;
            display: grid;
            grid-template-columns: 160px 160px 160px;
            gap: 1.5em;
        }
    
        &__badge--muted {
            img {
                width: 100%;
                height: 100%;
            }
        }

    }
}

@media (min-width: $screen-md) {

}


@media (max-width: $screen-sm) {
    .my-badges {
        padding: 1.25rem;

        .flex-col {
            justify-content: space-around;
        }

        &__badges__grid {
            align-self: center;
            display: grid;
            grid-template-columns: 100px 100px 100px;
            gap: 1.5em;
        }
    
        &__badge--muted {
            img {
                width: 100%;
                height: 100%;
            }
        }

    }
}

@media (max-width: $screen-xs) {
    .my-badges {
        padding: 1rem;

        .flex-col {
            justify-content: space-evenly;
        }

        &__badges__grid {
            align-self: center;
            display: grid;
            grid-template-columns: 80px 80px 80px;
            gap: 1em;
        }
    
        &__badge--muted {
            img {
                width: 100%;
                height: 100%;
            }
        }

    }
}