.explorer-mode {
    padding: 2em;

    .flex-col {
        align-items: center;
        height: 100%;
    }

    .flex-row {
        align-self: flex-start;
        align-items: center;

        h1 {
            color: #FFFF;
        }
    }

    &__explorer__card {
        width: 100%;
        height: 87%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #FFFF;
        margin: 3em 0;
        border-radius: 5em;
    }

    &__explorer__swipe {
        position: absolute;
        top: 91%;
        left: 50%;
        transform: translateX(-50%);
        color: #FFFEFF;
    }

    &__explorer__image {
        margin-top: -3em;

        img {
            max-width: 70%;
            width: 70%;
            margin-inline: auto;
        }
    }

    &__explorer__description {
        width: 70%;
        margin: 2em auto;

        h1,
        caption {
            text-align: left;
        }

        h1 {
            font-weight: 600;
            font-size: 2.5em;
        }

        caption {
            display: inline;
            font-size: 1.75em;
            opacity: 0.6;
        }
    }

    &__explorer__button {
        width: 85%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        // img {
        //     padding: 1rem;
        //     border-radius: 50%;
        //     cursor: pointer;
        //     background-color: $button-blue;
        //     box-shadow: 0 4px 0 $button-blue-dark;
        // }

        button {
            font-size: 24px !important;
            width: 150px;
            height: 50px;
        }
    }

    &__explorer__breadcrumb-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
    }

    &__explorer__breadcrumb {
        width: 10px;
        height: 10px;
        background: #FFFFFF;
        border-radius: 5px;
        opacity: 1;

        &--selected {
            width: 30px;
        }
    }

    @media (max-width: $screen-lg) {
        padding: 2em;

        .flex-row {
            h1 {
                font-size: 2.5em;
                margin-left: 1em;
            }
        }

        &__explorer__card {
            margin: 1em 0;
            border-radius: 5em;
        }

        &__explorer__image {
            margin-top: -3em;

            img {
                max-width: 70%;
                width: 60%;
                margin: 60px auto 0;
            }
        }

        &__explorer__description {
            width: 70%;
            margin: 2em auto;

            h1 {
                font-size: 2.5em;
            }

            caption {
                font-size: 1.75em;
            }
        }

        &__explorer__button {
            width: 85%;

            button {
                font-size: 2.5em !important;
                width: 250px;
                height: 100px;
            }
        }

        &__explorer__swipe {
            font-size: 2.5em;
        }

        &__explorer__breadcrumb {
            width: 10px;
            height: 10px;
            border-radius: 5px;

            &--selected {
                width: 30px;
            }
        }
    }

    @media (max-width: $screen-md) {
        .flex-row {
            h1 {
                font-size: 2em;
                margin-left: 1em;
            }
        }

        &__explorer__card {
            margin: 2.5em 0;
            border-radius: 5em;
        }

        &__explorer__image {
            margin-top: -2em;

            img {
                max-width: 60%;
                width: 100%;
            }
        }

        &__explorer__description {
            width: 80%;
            margin: 2em auto;

            h1 {
                font-size: 2em;
            }

            caption {
                font-size: 1.5em;
            }
        }

        &__explorer__button {
            width: 85%;

            button {
                font-size: 2em !important;
                width: 200px;
                height: 75px;
            }
        }

        &__explorer__swipe {
            font-size: 2em;
        }


    }

    @media (min-width: 750px) and (max-width: 780px) {
        &__explorer__image {

            img {
                width: 50%;
            }
        }

        &__explorer__description {

            h1 {
                font-size: 1.7em;
            }

            caption {
                font-size: 1.3em;
            }
        }

        &__explorer__button {

            button {
                font-size: 1.8em !important;
                width: 150px;
                height: 60px;
            }
        }

        &__explorer__card {
            height: 84%;
        }

        &__explorer__swipe {
            font-size: 1.5em;
            top: 91%;
        }
    }

    @media (max-width: $screen-sm) {
        padding: 0.75em;

        .flex-row {
            h1 {
                font-size: 1.25em;
                margin-left: 0.5em;
            }
        }

        &__explorer__card {
            margin: 1em 0;
            border-radius: 2em;
        }

        &__explorer__swipe {
            top: 92%;
        }

        &__explorer__image {
            margin-top: -1em;

            img {
                max-width: 100%;
                width: 90%;
            }
        }

        &__explorer__description {
            width: 90%;
            margin: 2em auto;

            h1 {
                font-size: 1.5em;
            }

            caption {
                font-size: 1em;
            }
        }

        &__explorer__button {
            width: 90%;

            img {
                padding: 0.5rem;
                width: 40px;
                height: 40px;
            }
        }

        &__explorer__button {
            width: 85%;

            button {
                font-size: 1.5em !important;
                width: 150px;
                height: 50px;
            }
        }

        &__explorer__swipe {
            font-size: 1.1em;
        }

        &__explorer__breadcrumb {
            &--selected {
                width: 20px;
            }
        }
    }



    @media (height: 737px) and (width: 415px) {
        &__explorer__image {

            img {
                max-width: 100%;
                width: 70%;
            }
        }
    }

    @media (min-width: 377px) and (max-width: 430px) {
        &__explorer__image {

            img {
                max-width: 50%;
                width: 50%;
            }
        }
    }

    @media (max-width: 376px) {
        &__explorer__image {

            img {
                max-width: 60%;
                width: 60%;
            }
        }

        &__explorer__description {
            width: 90%;
            margin: 1em auto;

            h1 {
                font-size: 1.5em;
            }

            caption {
                font-size: 1em;
            }
        }

        &__explorer__swipe {
            font-size: 1em;
        }
    }

    @media (max-width: $screen-xs) {
        &__explorer__image {
            margin-top: -1em;

            img {
                max-width: 100%;
                width: 60%;
            }
        }
    }

    @media (max-height: 500px) and (max-width: 320px) {

        &__explorer__image {
            margin-top: -4em;
        }

        &__explorer__description {
            width: 90%;
            margin: 0 auto;

            h1 {
                font-size: 1em;
            }

            caption {
                font-size: 0.8em;
            }
        }

        &__explorer__swipe {
            font-size: 0.8em;
        }

        &__explorer__button {

            button {
                font-size: 1.3em !important;
                width: 100px;
                height: 50px;
            }
        }

    }
}