.select-mode {
    .flex-col {
        align-items: center;
    }

    .flex-row {
        align-self: flex-start;
        align-items: center;

        h1 {
            color: $theme;
        }
    }

    &__select__mode, &__explorer__mode {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        h1, caption {
            color: #FFF;
            text-align: left;
            font-family: $font-filson-pro;
            letter-spacing: 1px;
        }

        h1 {
            font-weight: 600;
        }

        caption {
            display: inline;
            opacity: 0.6;
            width: 75%;
        }   
    }

    &__select__mode {
        background: url('../../../assets/svg/select-mode/story-mode.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__explorer__mode {
        background: url('../../../assets/svg/select-mode/explorer-mode.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__button__container {
        flex: 1;
        display: flex;
        align-items: flex-end;
        
        button {
            color: #FFF;
            box-shadow: none;
            border-radius: 1em;
            font-family: $font-filson-pro;
            font-weight: 100;
        }
    }

    &__button--story {
        background-color: #4D8425;
    }

    &__button--explorer {
        background-color: #D75F2A
    }

    @media (max-width: $screen-lg), (min-width: $screen-lg) {
        padding: 2em; 
        
        .flex-col {
            gap: 2em;
        }
    
        .flex-row {
            h1 {
                margin-left: 1em;
                font-size: 2.5em;
            }
        }

        &__select__mode {
            box-shadow: 0 8px 0 #b1e47d !important;
        }

        &__explorer__mode {
            box-shadow: 0 8px 0 #f1bd97 !important;
        }

        &__select__mode,  &__explorer__mode {
            height: 450px;
            width: 700px;
            border-radius: 5em;
            padding: 3em;
            gap: 1em;
            margin-bottom: 20px;

            h1 {
                font-size: 2.5em;
            }

            caption {
                font-size: 1.5em;
            }
        }

        &__button__container {

            button {
                height: 48pt;
                width: 141pt;
                font-size: 1.3em !important;
            }
        }
    }
    
    @media (max-width: $screen-md) {
        .flex-col {
            gap: 1.5em;
        }

        &__select__mode,  &__explorer__mode {
            height: 400px;
            width: 700px;
        }
    }

    @media (max-width: $screen-sm) {
        padding: 1em; 
        
        .flex-col {
            gap: 1em;
        }
    
        .flex-row {
            h1 {
                margin-left: 0.5em;
                font-size: 1.25em;
            }
        }

        &__select__mode {
            box-shadow: 0 4px 0 #b1e47d !important;
        }

        &__explorer__mode {
            box-shadow: 0 4px 0 #f1bd97 !important;
        }

        &__select__mode,  &__explorer__mode {
            height: 200px;
            width: 280px;
            border-radius: 2em;           
            padding: 1em;
            gap: 0.5em;

            h1 {
                font-size: 1.25em;
            }

            caption {
                width: 80%;
                font-size: 0.875em;
            }
        }

        &__button__container {
            button {
                height: 28pt;
                width: 88pt;
                font-size: 0.875em !important;
            }
        }
    }
    
    @media (max-width: $screen-xs) {

    }
}