.input-text {
    border: none;
    border-radius: 3rem;
    padding: 1rem 2rem;
    outline: none;
    width: 352px;
    height: 76px;
    font-size: 1.25rem;
    font-weight: bold;
    color: $text-color-main;

    &::placeholder {
        font-weight: bold;
        color: $text-color-main;
        opacity: 0.4;
    }

    &--light {
        border-color: $bg-blue;

        &::placeholder {
            font-weight: normal;
        }
    }

    &--light-gray {
        border-color: $bg-blue;

        &::placeholder {
            font-weight: normal;
            color: $bg-blue;
        }
    }
}