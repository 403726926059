@import './with-animation-transition';
@import './use-goto';


.slide-view {
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    &__slider {
        display: flex;
        height: 100%;
        transition: transform 1s ease-in-out;
        
        > div {
            flex: 1;
            opacity: 0;
            transition: opacity .9s ease-in-out;
            
            &.active-slide {
                opacity: 1;
            }
        }
    }
}