.story {
    .flex-col {
        align-items: center;
        // justify-content: space-around;
        align-items: center;
        height: $pageHeight;
    }

    .flex-col-nh {
        align-items: center;
        justify-content: space-evenly;
        height: $pageHeight;
    }

    .flex-col-hnone {
        align-items: center;
        justify-content: space-evenly;
    }

    .flex-row {
        padding: 0 1em;
        height: 10%;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        caption {
            font-family: $font-filson-pro;
            color: #fff;
            cursor: pointer;
            font-size: 1rem;
        }
    }

    .pt-1 {
        padding-top: 1em !important;
    }
    
    h2, p {
        font-family: 'AkhandSoft-Bold', Arial, sans-serif;;
        text-align: center;
        color: #FFFEFF;
    }

    h2 {
        font-size: 3rem;
    }

    p {
        font-size: 1.5rem;
    }



    &__story {
        width: 100%;   
        img {
            margin: 0 auto;
        }
    }

    &__story__page, &__story__page--w100 {
        img {
            margin: 0 auto;
        }
    }

    
    &__story__page--w100 {
        width: 100%;

        img {
            width: 100%;
        }
    }

    &__story__with-button {
        width: 85%;   
    }
}

@media (max-width: $screen-lg), (min-width: $screen-lg) {
    
}

@media (min-width: $screen-md) {
    .story {
        h2 {
            font-size: 3.25rem;
        }

        p {
            font-size: 2rem;
        }

        .flex-row {
            padding: 1.5em 2em 0;
            caption {
                font-size: 1.5rem;
            }
        }

        &__story__page {
            width: 100%;
            img {
                width: 80%;
            }
        }

        .button-wrapper {
            padding: 2em;
        }
    }
}


@media (max-width: $screen-sm) {
    .story {
        .flex-col {
            gap: 1em;
        }
        
        .flex-row {
            padding: 0 1em;
        }

        .button-wrapper {
            padding-bottom: 2em;
        }
    }
}

@media (max-width: $screen-xs) {
    .story {
        .flex-col {
            gap: 0.5em;
        }

        .flex-row {
            padding: 0 1em;
        }

        .button-wrapper {
            padding-bottom: 2em;
        }
    }
}