$theme: rgb(97, 177, 206);
$theme-light: #6EB8D4;
$theme-dark: #549DBA;

$text-color-main: rgb(47,59,74);
$text-color-sub: rgba(90, 102, 117, 0.8);
$text-color-light: rgba(90,102,117, 0.6);
$text-color-disabled: #e4e4e4;

$background-dark:#303b4a;
$background-darker: #121820;
$background-light: rgb(90,102,117);
$background-lighter: #D3D3D3;

$bg-blue: #61B1CE;
$bg-blue-light: #6EB8D4;
$bg-blue-dark: #549DBA;
$bg-light: #FCEFD5;
$bg-orange: #EB9B61;
$bg-yellow: #F3C960;
$bg-gray: #00000029;

$button-green: #8AB85B;
$button-green-dark: #688643;

$button-red: #E96C58;
$button-red-dark: #CC3E28;

$button-blue: $bg-blue;
$button-blue-dark: #549DBA;

$font-filson-soft: 'Filson-Soft', sans-serif;
$font-filson-pro: 'Filson-Pro', sans-serif;

$pageHeight: calc(var(--window-height));

$screen-xs: 320px;
$screen-sm: 500px;
$screen-md: 820px;
$screen-lg: 1024px;
