.result-emoji {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 95%;
    width: 100%;

    h1 {
        margin: 3em 0;
    }

    caption {
        display: block;
        color: #111118;
        text-align: center;
    }

    .result__image {
        z-index: 1;
        margin-bottom: -4em;
    }

    .result__image--neutral {
        z-index: 1;
        margin-bottom: -2em;
    }

    .result__container {
        z-index: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #FFF;
        width: 90%;
        border-radius: 100px;

        h2 {
            display: block;
            color: #090000;
            margin: 0;
            text-align: center;
        }

        .icon {
            height: 50px;
            width: 175px;
            border-bottom-left-radius: 100px;
            border-bottom-right-radius: 100px;
        }

        .result__content-title {
            margin: 5em 0 2em;
            width: 90%;
        }

        .result__content-description {
            width: 90%;
        }

        .result__button {
            flex: 1;
            display: flex;
            align-items: flex-end;
            margin-bottom: 2em;
        }
    
    }

    @media (max-width: $screen-lg), (min-width: $screen-lg) {
        caption {
            font-size: 1.25rem;
        }
    }
    
    @media (max-width: $screen-md) {

    }
    
    @media (height: 954px) and (width: 768px) {
        .result__container {
    
            .result__content-title {
                margin: 1em 0 2em;
                width: 90%;
            }
    
            .result__button {
                flex: 1;
                display: flex;
                align-items: unset;
                margin-bottom: 0;
            }
        
        }
    }
    
    @media (max-width: $screen-sm) {
        h1 {
            margin: 0.75em 0;
        }

        caption {
            font-size: 1rem;
        }

        .result__image, .result__image--neutral {
            width: 80%;
        }

        .result__image {
            margin-bottom: -2em;
        }

        .result__image--neutral {
            margin-bottom: -1em;
        }

        .result__container {
            width: 95%;
            border-radius: 40px;
    
            .icon {
                height: 25px;
                width: 120px;
                border-bottom-left-radius: 50px;
                border-bottom-right-radius: 50px;
            }
    
            .result__content-title {
                width: 90%;
                margin: 1em 0;
            }
    
            .result__content-description {
                width: 90%;
            }
    
            .result__button {
                flex: 1;
                display: flex;
                align-items: flex-end;
                margin-bottom: 1.5em;
            }
        
        }
    }
    
    @media (max-width: $screen-xs) {

    }

}