.profile {
    padding: 2rem;

    h1 {
        color: $theme;
        margin-left: 0.5em;
    }

    p {
        letter-spacing: 0.01px;
        color: #09000099;
    }

    .flex-col {
        justify-content: space-around;
        gap: 1em;
        height: 100%;
    }

    &__profile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 50%;
        width: 100%;
        margin-inline: auto;
        border-radius: 2rem;
        background-color: #FFFFFF;
        box-shadow: 0 4px 0 #C8C6C9;

        .rc-progress-line {
            width: 80%;
        }
    }

    &__profile-wrapper {
        @extend .flex-row;
        justify-content: flex-end;
        align-items: center;
        background-image: linear-gradient(to right, hsl(40,87,70), #F9DEB0 );        
        width: 90%;
        border-radius: 2rem;
        margin-top: 1em;
        margin-inline: auto;
        text-align: center;
    }

    &__profile-avatar {
        margin-inline: auto;
        padding-left: 3em;
    }

    &__profile-star {
        margin-right: 1em;
    }

    &__profile-name {
        margin-top: 3em;

        h1 {
            color: $text-color-main;
            margin: 0;
            display: inline-block;
            margin-right: 0.5em;
        }

        img {
            cursor: pointer;
            display: inline-block;
        }
    }

    &__badges-progress {
        @extend .flex-row;
        justify-content: center;
        align-content: center;
        gap: 1em;
        width: 100%;
        flex: 1;
    }

    &__badges-earned,  &__total-progress {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 2rem;
        background-color: #FFFFFF;
        box-shadow: 0 4px 0 #C8C6C9;
        flex: 1;

        h2 {
            color: $bg-blue;
        }

        caption {
            letter-spacing: 0.01px;
            color: #09000099;
            font-size: smaller;
        }
    }

    .avatar {
        @extend .flex-center;
        background-color:#E2E3D0;
        border: 3px solid #ffff;
        border-radius: 50%;
        margin: 1em 0 -1em 0;
        width: 80px;
        height: 80px;
        
        img {
            margin-top: 1.25rem;
            width: 75%;
        }
    }

    &__profile__avatar-container {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    &__profile__avatar-breadcrumb-container {
        @extend .flex-row;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
    }

    &__profile__avatar-breadcrumb-current {
        width: 20px;
        height: 10px;
        background: #FFFFFF;
        border: 1px solid #EB9B61;
        border-radius: 5px;
        opacity: 1;
    }

    &__profile__avatar-breadcrumb {
        width: 10px;
        height: 10px;
        background: #FFFFFF;
        border-radius: 5px;
        opacity: 1;
    }

    &__profile__avatar {
      @extend .flex-row;
      justify-content: space-evenly;
      align-items: center;
      flex: 1;
    }

    &__avatar-item, &__avatar-item-girl3  {
        @extend .flex-center;
        background-color:#E2E3D0;
        border-radius: 50%;
        width: 40px;
        height: 40px;

        img {
            margin: 1.5rem auto 0;   
        }

        &--selected {
            border: 3px solid #EB9B61;
        }

        &--not-selected {
            border: none;;
        }
    }

    &__avatar-item {
        img {
            width: 60%;
        }
    }

    &__avatar-item-girl3 {
        img {
            width: 75%;
        }
    }

    &__input-wrapper {
        height: 100%;
        position: relative;
    }

    &__input {
        width: 100%;
        height: 70px;
        border: none;
        border-radius: 3em;
        outline: none;
        padding: 0rem 2rem;
        font-size: 14px;
        font-weight: bold;
        color: $text-color-main;
    }

    &__input__close {
        display: inline-block;
        background-color: #c8c6c9;
        border: none;
        border-radius: 50%;
        height: 50%;
        margin: 0;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
        position: absolute;        
        @extend .flex-center;

        &--animate {
            transition: all 0.5s ease-in-out;
            opacity: 1;
            cursor: pointer;
        }

        &--animate-out {
            transition: all 0.5s ease-in-out;
            opacity: 0; 
            cursor: auto;
        }
    }

    .MuiInputBase-root {
        background-color: #FFFFFF;
        width: 100%;
        height: 70px;
        border: none;
        border-radius: 3em;
        outline: none;
        font-size: 2rem;
        font-weight: bold;
        color: $text-color-main;
    }

    &__button-wrapper {
        button {
            width: 100% !important;
        }
    }

    &__modal__save {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        h1 {
            font-family: $font-filson-pro;
        }
    }
}

@media (max-width: $screen-lg), (min-width: $screen-lg) {
    .profile {
        &__profile {
            p {
                font-size: 2.5rem;
                margin: 1em 0;
            }
        }

        &__profile-name {
            h1 {
                font-size: 3rem;
            }

            img {
                height: 36px;
                width: 36px;
            }
        }

        &__badges-earned,  &__total-progress {
            h2 {
                font-size: 3rem;
            }

            caption {
                font-size: 2.5rem;
            }
        }

        &__avatar-item, &__avatar-item-girl3 {
            width: 120px;
            height: 120px;
        }
        
        &__profile__avatar-breadcrumb-current {
            width: 50px;
            height: 15px;
            border: 2px solid #EB9B61;
        }
    
        &__profile__avatar-breadcrumb {
            width: 20px;
            height: 15px;
        }
    }
}

@media (max-width: $screen-md) {
    .profile {
        &__profile {
            p {
                font-size: 1.875rem;
                margin: 1em 0;
            }
        }

        &__profile-name {
            h1 {
                font-size: 2.75rem;
            }

            img {
                height: 36px;
                width: 36px;
            }
        }

        &__input {
            font-size: 24px;
        }

        &__badges-earned,  &__total-progress {
            h2 {
                font-size: 2.5rem;
            }

            caption {
                font-size: 1.5rem;
            }
        }

        &__avatar-item, &__avatar-item-girl3 {
            width: 100px;
            height: 100px;
        }
    }
}


@media (max-width: $screen-sm) {
    .profile {
        &__profile {
            p {
                font-size: 1.25rem;
                margin: 1em 0;
            }
        }

        &__profile-name {
            h1 {
                font-size: 1.5rem;
            }

            img {
                height: 24px;
                width: 24px;
            }
        }

        &__input {
            font-size: 14px;
        }

        &__badges-earned,  &__total-progress {
            h2 {
                font-size: 2rem;
            }

            caption {
                font-size: 1.25rem;
            }
        }

        &__avatar-item, &__avatar-item-girl3 {
            width: 80px;
            height: 80px;
        }

        
        &__profile__avatar-breadcrumb-current {
            width: 30px;
            height: 15px;
            border: 2px solid #EB9B61;
        }
    
        &__profile__avatar-breadcrumb {
            width: 15px;
            height: 15px;
        }
    }
}

@media (max-width: $screen-xs) {
    .profile {
        padding: 1rem;

        &__profile {
            p {
                font-size: 1rem;
                margin: 1em 0;
            }
        }

        &__profile-name {
            h1 {
                font-size: 1rem;
            }

            img {
                height: 24px;
                width: 24px;
            }
        }

        &__badges-earned,  &__total-progress {
            h2 {
                font-size: 1.875rem;
            }

            caption {
                font-size: 0.875rem;
            }
        }

        &__avatar-item, &__avatar-item-girl3 {
            width: 50px;
            height: 50px;
        }

        &__profile__avatar-breadcrumb-current {
            width: 20px;
            height: 10px;
            border: 1px solid #EB9B61;
        }
    
        &__profile__avatar-breadcrumb {
            width: 10px;
            height: 10px;
        }
    }   
}