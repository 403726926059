.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.position-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.position-center-fixed {
    position:fixed;
    @extend .position-center;
}

.position-center-absolute {
    position:absolute;
    @extend .position-center;
}