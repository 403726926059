.confirmation__answerfeedback {
    justify-content: center;
    gap: 1em;
}

.confirmation {
    justify-content: space-between;
}

.confirmation, .confirmation__answerfeedback {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-family: $font-filson-pro;
        text-align: center;
    }

    caption {
        display: inline-block;
    }

    .confirmation__image-container {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        img {
            padding: 0.5rem;
            border-radius: 50%;
            cursor: pointer;
            background-color: $button-red;
            box-shadow: 0 4px 0 $button-red-dark;
        }
    }

    .confirmation__button-container {
        
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
 
}   

@media (max-width: $screen-lg), (min-width: $screen-lg) {
    .confirmation, .confirmation__answerfeedback {
        h1 {
            font-size: 3rem;
        }
        caption {
            font-size: 2rem;
        }
    }

    .confirmation__answerfeedback {
        gap: 5em;
    }
}

@media (max-width: $screen-md) {
    .confirmation, .confirmation__answerfeedback {
        h1 {
            font-size: 2.5rem;
        }
        caption {
            font-size: 1.5rem;
        }
    }

    .confirmation__answerfeedback {
        gap: 4em;
    }
}


@media (max-width: $screen-sm) {
    .confirmation, .confirmation__answerfeedback {
        h1 {
            font-size: 1.75rem;
        }
        caption {
            font-size: 1rem;
        }
    }

    .confirmation__answerfeedback {
        gap: 3em;
    }
}

@media (max-width: $screen-xs) {
    .confirmation, .confirmation__answerfeedback {
        h1 {
            font-size: 1.25rem;
        }
        caption {
            font-size: 0.985rem;
        }
    }

    .confirmation__answerfeedback {
        gap: 2em;
    }
}