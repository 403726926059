.user-info {

    .version-text {
        position: absolute;
        font-size: 12px;
        color: #fff;
        top: 97%;
        left: 1%;
    }

    h1 {
        color: #FFFFFF;
        margin-left: 0.5em;
    }

    .flex-col {
        justify-content: space-around;
        align-items: center;
    }

    .swipeable-container {
        background-image: url('../../../assets/svg/bricks-bg.svg');
    }

    .toc-container {
        background-color: #fff;
        position: absolute;
        width: 100vw;
        height: 100vh;
        padding-left: 31px;
        padding-right: 31px;
        overflow-y: auto;

        .toc-logo {
            width: 189px;
            height: 28px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 61px;
            margin-bottom: 46px;

            .evf-logo {
                width: 100%;
            }
        }

        .toc-title {
            font-family: $font-filson-soft;
            font-size: 18px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 130px;
            margin-bottom: 46px;
        }

        .toc-text {
            margin-bottom: 23px;

            .toc-text-title {
                font-family: $font-filson-soft;
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .toc-text-paragraph {
                font-family: $font-filson-soft;
                font-size: 16px;
                margin-bottom: 23px;

                ul {
                    list-style-type: '- ';
                }
            }
        }

        .toc-checkbox {
            display: flex;

            .checkbox-container {
                margin-right: 15px;
            }
        }

        .toc-button {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 189px;
            height: 50px;
            margin-top: 23px;
            margin-bottom: 23px;

            button {
                border-radius: 10px;
                font-size: 18px;
                width: 100%;
                height: 100%;

                &.btn-disabled {
                    background-color: lightgray;
                    box-shadow: 0 4px 0 gray;

                    &:hover {
                        background-color: lightgray;
                        box-shadow: 0 4px 0 gray;
                    }
                }

                &.btn-enabled {
                    background-color: #E96C58;
                    box-shadow: 0 4px 0 #CC3E28;
                }
            }
        }
    }

    &__home-icon {
        color: $text-color-disabled;
        background-color: #C8C6C9 !important;
        box-shadow: 0 4px 0 #B2B2B2 !important;
    }

    &__bear {
        position: relative;
        width: 100%;
    }

    &__bear__body {
        width: 100%;
    }

    &__bear__input-container {
        width: 170%;
        // aspect-ratio: 9/2;
        position: absolute;
        top: 85%;
        left: 50%;
        transform: translate(-50%, -150%);
    }

    &__bear__input-container-email {
        width: 170%;
        // aspect-ratio: 9/2;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -150%);
    }

    &__bear__input-container-age {
        width: 170%;
        // aspect-ratio: 9/2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -150%);
    }

    &__bear__input-wrapper {
        height: 100%;
        position: relative;
    }

    &__bear__input {
        width: 100%;
        height: 92px;
        border: none;
        border-radius: 3em;
        outline: none;
        padding-left: 28px;
        font-size: 1.2rem;
        font-weight: bold;
        color: $text-color-main;
    }

    &__bear__close {
        background-color: #c8c6c9;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: none;
        position: absolute;
        top: 22%;
        right: 5%;
        transform: translateY(-50%);
        @extend .flex-center;
    }

    .bear__hands {
        position: absolute;
        top: -12%;
        width: 13%;
    }

    .bear__hands--right {
        right: 15%;
    }

    .bear__hands--left {
        left: 12%;
        z-index: 1;
    }

    .id-container {
        position: relative;
        width: 100%;
        height: 92px;
        margin-top: 20px;

        .id-input-bg {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            height: 92px;
        }

        .bear__idInput {
            font-size: 16px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 70%;
            z-index: 12;
            outline: 0;
            border: 0;
            margin-top: 30px;
        }

    }

    .MuiInputBase-root {
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 3em;
        outline: none;
        font-size: 3rem;
        font-weight: bold;
        color: $text-color-main;
    }

    .MuiSelect-icon {
        height: 50%;
        aspect-ratio: 1/1;
        border: none;
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
        @extend .flex-center;
    }

    .MuiSvgIcon-root {
        width: 3em;
    }

    .MuiSelect-select.MuiSelect-select {
        padding: 0;
    }

    .MuiSelect-selectMenu {
        @extend .flex-center;
        height: 92px;
    }

    &__bear__close,
    &__button--finish {
        &--animate {
            transition: all 0.5s ease-in-out;
            opacity: 1;
            cursor: pointer;
        }

        &--animate-out {
            transition: all 0.5s ease-in-out;
            opacity: 0;
            cursor: auto;
        }
    }

    .warning-container {
        margin-top: 10px;
        background-color: rgba(28, 28, 28, 1);
        text-align: center;
        color: red;
    }

    &__button--name {
        &--btn-disabled {
            background-color: lightgray;
            box-shadow: 0 4px 0 gray;

            &:hover {
                background-color: lightgray;
                box-shadow: 0 4px 0 gray;
            }
        }

        &--btn-enabled {
            background-color: #E96C58;
            box-shadow: 0 4px 0 #CC3E28;
        }
    }

    &__bear--kid {
        position: relative;
        width: 100%;
        max-width: 16rem;
    }

    &__bear__glasses {
        position: absolute;
        top: 12.5%;
        left: 75.5%;
        transform: translate(-50%, -50%);

        &--animate {
            animation: glasses-on 250ms ease-in-out forwards;
        }

        &--animate-out {
            animation: glasses-off 250ms ease-in-out forwards;
        }
    }

    @keyframes glasses-on {
        0% {
            opacity: 0;
        }

        33% {
            opacity: 0.33;
        }

        66% {
            opacity: 0.66;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes glasses-off {
        0% {
            opacity: 1;
        }

        33% {
            opacity: 0.66;
        }

        66% {
            opacity: 0.33;
        }

        100% {
            opacity: 0;
        }
    }

    &__avatar-container {
        display: grid;
        grid-template-columns: 160px 160px;
        gap: 1.5em;
    }

    &__avatar-item,
    &__avatar-item-girl3,
    &__avatar-item--boy {
        border-radius: 5rem;
        background-color: $bg-blue-light;
        cursor: pointer;

        &--selected {
            background-color: $bg-blue-dark;
        }

        &--not-selected {
            background-color: $bg-blue-light;
        }
    }

    &__avatar-item {
        img {
            width: 60%;
            margin: 2rem auto 0;
        }
    }


    &__avatar-item--boy {
        img {
            width: 61%;
            margin: 2rem auto 0;
        }
    }

    &__avatar-item-girl3 {
        img {
            width: 75%;
            margin: 2rem auto 0;
        }
    }

    @media (max-width: $screen-lg),
    (min-width: $screen-lg) {
        &__bear {
            max-width: 16rem;
        }

        .flex-col {
            justify-content: space-evenly;
        }
    }

    @media (max-width: $screen-md) {
        &__bear {
            max-width: 16rem;
        }

        &__bear__input-container {
            top: 85%;
        }

        &__bear__input-container-email {
            top: 60%;
        }

        .flex-col {
            justify-content: space-evenly;
        }
    }

    @media (height: 954px) and (width: 768px) {
        &__bear__input-container {
            top: 85%;
        }

        &__bear__input-container-email {
            top: 60%;
        }

        &__bear__input {
            font-size: 16px;
        }
    }

    @media (max-width: $screen-sm) {
        &__bear {
            max-width: 12rem;
        }

        &__bear__input-container {
            top: 100%;
        }

        &__bear__input-container-email {
            top: 60%;
        }

        &__bear__input {
            font-size: 1rem;
        }
    }

    @media (max-width: $screen-xs) {
        .title-wrapper {
            margin-top: 23px;
        }

        h1 {
            font-size: 1em;
        }

        &__home-icon {
            width: 35px !important;
            height: 35px !important;
        }

        &__bear,
        &__bear--kid {
            max-width: 10rem;
        }

        &__bear__input-container {
            top: 115%;
        }

        &__bear__input {
            font-size: 12px;
        }

        &__avatar-container {
            grid-template-columns: 100px 100px;
        }

        .id-container {
            .id-input-bg {
                height: 85px;
            }
        }
    }

    @media (min-width: 374px) and (max-width: 376px) {
    }

    @media (min-width: 390px) and (max-width: 394px) and (min-height: 844px) and (max-height: 853px) {
        .bday-slide {
            padding-top: 80px;
        }
     }

    @media (height: 480px) and (width: 320px) {
        &__bear__input-container-age {
            top: 65%;
        }
    }
}