.no-result {
    text-align: center;
    margin-top: 5rem;
}

.result-page {
    .results {
        overflow: auto;
        height: 80vh;

        .res-btn {
            height: auto;
            background-color: #FBFBFB;
            border-radius: 1rem;
            margin: 2rem;
            padding: 1rem 1rem;
            display: flex;

            .store-logo {
                min-width: 40px;

                img {
                    width: 1.5rem;
                    margin-right: 1rem;
                    border-radius: 2px;
                }
            }

            .store-details {
                .store-name {
                    font-size: 1.1rem;
                    font-weight: 900;
                    margin-bottom: 0.5rem;
                }

                .store-address {
                    color: #63646B;
                    margin-bottom: 0.5rem;
                }

                .store-availability {
                    .day-start-end {
                        .day {
                            font-size: 0.9rem;
                            color: #61B1CE;
                            margin-right: 1rem;
                        }

                        .start-end {
                            color: #63646B;
                            margin-bottom: 0.5rem;
                            font-size: 0.9rem;
                        }
                    }
                }

                .redirect-map {
                    all: unset;
                    background-color: #E96C58;
                    padding: 0.5rem 1.5rem;
                    border-radius: 5rem;
                    color: white;
                    margin-top: 1rem;
                    border-bottom: 4px solid #be513f;
                }
            }
        }

        .res-btn.active {
            background-color: #F2F7FD;
            border: 1px solid #67B4D0;
        }
    }
}

.header {
    height: 10rem;
    padding: 1rem 2rem;
    position: sticky;
    top: 0;
    background-color: white;

    .upper-header {
        display: flex;

        img.header-back-icon {
            width: 100%;
        }

        button.header-back {
            all: unset;
            background-color: #E96C58;
            padding: 0.5rem 0.8rem;
            font-size: 2.5rem;
            text-align: center;
            min-width: 2rem;
            min-height: 2.5rem;
            border-radius: 50%;
            color: white;
            border-bottom: 4px solid #be513f;
        }

        input.search-input {
            flex: 1;
            all: unset;
            background-color: #FBFBFB;
            padding: 0.5rem 1rem;
            border-radius: 1rem;
            margin: 0.5rem 1rem;
            width: -webkit-fill-available;
            height: 1.5rem;
            -webkit-user-select: text;
        }
    }

    .lower-header {
        .select-btn {
            all: unset;
            background-color: #FBFBFB;
            border: 1px solid #C8C6C9;
            padding: 0.5rem 2rem;
            border-radius: 2rem;
            margin-left: 4.7rem;
        }

        .dropdown-days-selector {
            position: absolute;
            margin-left: 4.8rem;
            background-color: white;
            padding: 1rem;
            border: 1px solid #d2d0d2;
            border-radius: 0.5rem;
            margin-top: 0.3rem;

            input {
                margin: 10px;
                margin-right: 1rem;
            }
        }

        .search-btn {
            all: unset;
            position: relative;
            left: 100%;
            transform: translateX(-100%);
            background-color: #E96C58;
            padding: 0.5rem 1.5rem;
            border-radius: 5rem;
            color: white;
            margin-top: 1rem;
            border-bottom: 4px solid #be513f;
            margin-left: 1rem;
        }
    }
}