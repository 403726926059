.my-results {
    overflow: auto;

    .flex-col {
        height: $pageHeight;
        justify-content: flex-start;
    }

    .flex-row {
        align-items: center;
    }

    h1 {
        color: $theme;
        margin-left: 1em;
    }

    &__results-container {
        display: grid;
        grid-template-columns: 1fr 2fr;
        background: #FAE0B4 0% 0% no-repeat padding-box;
        border-radius: 18px;
    }

    &__results--image {
        img {
            width: 100%;
        }
    }

    &__results__title-description {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__results--title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        h2 {
            font-family: $font-filson-pro;
            color: #02020A;
        }
    }


    &__results--description {
        width: 90%;
        caption {
            display: inline-block;
            text-align: left;
            letter-spacing: 0;
            color: #02020A;
            font-family: $font-filson-pro;
            opacity: 0.8;
        }
    }

    &__results--progress {
        width: 90%;
    }

    @media (max-width: $screen-lg), (min-width: $screen-lg) {
        padding: 2em;

        .flex-col {
            gap: 1em;
        }

        &__results__first {
            margin-top: 2em;
        }

        &__results--title {
            gap: 1em;
        }

        &__results-container {
            padding: 1rem;
        }

        &__results--description {
            margin-top: -5em;
            caption {
                font-size: 1.25rem;
            }
        }    
    }
    
    @media (max-width: $screen-md) {
        padding: 2em;

        .flex-col {
            gap: 1em;
        }

        &__results__first {
            margin-top: 2em;
        }

        &__results--title {
            gap: 1em;
        }

        &__results-container {
            padding: 1rem;
        }

        &__results--description {
            margin-top: -5em;
            caption {
                font-size: 1.25rem;
            }
        }    
    }
    
    
    @media (max-width: $screen-sm) {
        padding: 1em;

        .flex-col {
            gap: 0.75em;
        }

        &__results__first {
            margin-top: 1em;
        }

        &__results-container {
            padding: 0.5rem;
        }
    
        &__results--image {
            
            img {
                width: 100%;
            }
        }
    
        &__results--title {
            gap: 0.5em;
    
            h2 {
                font-size: 1.25rem;
            }
        }
    
    
        &__results--description {   
            margin-top: 0;         
            caption {
                font-size: 0.975rem;
            }
        }
    }
    
    @media (max-width: $screen-xs) {
        padding: 1em;

        .flex-col {
            gap: 0.5em;
        }

        &__results__first {
            margin-top: 0.75em;
        }

        &__results--title {
            gap: 0.5em;
    
            h2 {
                font-size: 1rem;
            }
        }
    
    
        &__results--description {            
            caption {
                font-size: 0.875rem;
            }
        }
    }
} 