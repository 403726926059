.eyecare-tips {
    padding: 2rem;
    overflow: auto;
    
    .flex-col {
        height: 100%;
        justify-content: flex-start;
    }

    h1 {
        color: $theme;
        margin-left: 0.5em;
    }

    &__tips-container {
        @extend .flex-row;
        padding: 0.5rem;
        background: #FAE0B4 0% 0% no-repeat padding-box;
        border-radius: 18px;
    }

    &__tips__title-description {
        flex: 1;
    }

    &__tips--title {
        text-align: left;

        h2 {
            font-family: $font-filson-pro;
        }
    }

    &__tips--description {
        width: 90%;

        caption {
            display: inline;
            text-align: left;
            letter-spacing: 0;
            color: #02020A;
        }
    }
}

@media (max-width: $screen-lg),(min-width: $screen-lg)  {
    .eyecare-tips {
        padding: 2rem;

        .flex-col {
            gap: 1em;
        }

        &__tips-container {
            img {
                width: 30%;
            }
        }
    
        &__tips--title {
            h2 {
                font-size: 2.5rem;
            }
        }
    
        &__tips--description {
            caption {
               font-size: 1.875rem;
            }
        }
    }
}

@media (max-width: $screen-md) {
    .eyecare-tips {
        padding: 2rem;

        .flex-col {
            gap: 1em;
        }

        &__tips-container {
            img {
                width: 30%;
            }
        }
    
        &__tips--title {
            h2 {
                font-size: 2rem;
            }
        }
    
        &__tips--description {
            caption {
               font-size: 1.575rem;
            }
        }
    }
}

@media (max-width: $screen-sm) {
    .eyecare-tips {
        padding: 1rem;

        .flex-col {
            gap: 0.5em;
        }

        &__tips-container {
            img {
                width: 40%;
            }
        }
    
        &__tips--title {
            h2 {
                font-size: 1rem;
            }
        }
    
        &__tips--description {
            caption {
               font-size: 0.875rem;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    .eyecare-tips {
        padding: 1rem;

        .flex-col {
            gap: 0.5em;
        }

        &__tips-container {
            img {
                width: 40%;
            }
        }
    
        &__tips--title {
            h2 {
                font-size: 0.875rem;
            }
        }
    
        &__tips--description {
            caption {
               font-size: 0.775rem;
            }
        }
    }
}